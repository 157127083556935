/* React MapPanel */

.map-panel {
  position: relative;
  z-index: var(--z04-map-toolbar);
  font-size: 1rem;
}

/* Style for map panel button to show it */
.map-panel-button-show {
  position: absolute;
  top: 6px;
  right: 4px;
}

/* Style for map panel */
.map-panel .panel {
  border-bottom: var(--ui-border);
  z-index: var(--z07-menu);
}

.map-panel-collapsible {
  color: var(--ui-component-text-color);
  background-color: var(--ui-editor-background-color);

  .panel-body {
    padding: 4px;
    padding-top: 6px;
  }

  .map-panel-toolbar {
    display: flex;
    align-items: center;
  }
}

/* Style for all map panel buttons */
.map-panel button {
  height: 32px;
  width: 32px;
}

.map-panel .btn-group {
  margin-left: 2px;
}

/* Style for button groups */
.map-panel-zoom-container {
  display: flex;
}

.map-panel-zoom {
  width: 36px;
  margin-right: 4px;
  font-size: 0.75em;
  text-align: center;
  line-height: 32px;
  flex: 0 0 auto;
}

.buttons-plusminus {
  display: flex;
  flex: 0 0 auto;

  .btm {
    font-size: 0.8em; /* slightly smaller */
  }
}

.buttons-locate {
  flex: 0 0 auto;
}

.buttons-toggle {
  flex: 0 0 auto;
}
