/* DRAG & DROP FILE LOADER */

.filedrop-container {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--z01-filedrop);
  background-color: rgba(0, 0, 0, 0.8);
  cursor: copy;
  user-select: none;
  pointer-events: auto;
}

.filedrop-indicator {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 600px;
  top: 50%;
  margin-top: -300px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.filedrop-icon {
  font-size: 14em;
}

.filedrop-label {
  margin-top: 1.5em;
  font-family: var(--font-family);
  font-weight: 200;
  font-size: 2.5em;
}
