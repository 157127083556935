/* MODALS */

.modal {
  display: block; /* Default display mode */
  width: 400px;
  padding: 30px;
  font-family: var(--font-family);
  font-weight: 200;
  font-size: 1rem;
  text-align: center;
  color: var(--ui-component-text-color);
  background-color: var(--ui-base-color);
  box-shadow: var(--ui-modal-shadow), var(--ui-emboss-shadow);
  z-index: var(--z01-modal);
  pointer-events: auto;
  border: 1px solid var(--ui-border-color);
  border-radius: 2px;
}

/*
  For error modals we want the message to be user-selectable so it can be
  copy-pasted elsewhere.
*/
.modal:not(.error-modal) {
  user-select: none;
  cursor: default;
}

.modal-alt {
  text-align: left;

  .modal-buttons {
    justify-content: flex-end;
  }
}

.modal-text {
  display: inline-block;
  margin: 0;
  padding: 0;
  line-height: 1.4;

  a {
    text-decoration: underline;
  }
}

.modal-content {
  margin-top: 20px;
}

/* used in save-to modals */
.modal-content p {
  margin-top: 1em;
}

.modal-well {
  border: var(--ui-border);
  box-shadow: var(--ui-well-shadow);
}

.modal-buttons {
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.loading-spinner {
  margin-right: 30px;
  line-height: 40px;
  color: var(--ui-subtext-color);
  opacity: 0;
  visibility: hidden;

  &.loading-spinner-on {
    opacity: 1;
    visibility: visible;
  }

  .bt-spinner {
    margin-right: 0.25em;
  }
}

.open-url-modal {
  width: 600px;
}

.modal-about-text {
  margin-left: 40px;
  margin-right: 40px;

  p {
    font-size: 12px;
    margin-top: 16px;
    margin-bottom: 0;

    /* Reset text selection state so that text is copy-pastable */
    user-select: auto;
    cursor: auto;
  }
}

.save-to-cloud-modal {
  width: 600px;
}

.save-to-cloud-success-modal {
  /* Overrides for this modal.
     TODO: Consider refactoring modal so we don't need any of this */
  /* Inputs */
  .saved-scene-copy-btn {
    margin-left: 2px;
    width: 40px;
    height: 34px;
  }
}

.save-existing-to-cloud-modal {
  width: 500px;

  /* re-using this CSS from OpenFromCloudModal */
  .open-scene-option {
    padding: 0;
    cursor: auto;
    user-select: auto;
  }

  .open-scene-option:hover {
    background-color: transparent;
  }
}

.modal {
  position: relative;
}
