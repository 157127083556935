/* React MenuBar */

nav {
  border: 0;
  user-select: none;
}

.navbar {
  height: var(--menu-bar-height);
}

.menu-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: var(--z07-menu);
}

.menu-bar .container {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  background-color: var(--ui-base-color);
  border-bottom: 1px solid #1a1c1f;
}

.brand {
  position: relative;
  padding: 8px 12px 12px 15px;
  font-size: 14px;
  font-weight: 300;
  color: var(--ui-component-text-color);
  z-index: 1; /* This just needs to be any number to be properly sorted in its stacking context */
}

.brand-tag {
  position: absolute;
  left: 15px;
  top: 20px;
  font-size: 9px;
  color: var(--ui-highlight-color);
}

.menu-bar .btm {
  padding-right: 8px;
  font-size: 15px;
}

.menu-bar a {
  position: relative;
  display: block;
  color: var(--ui-component-text-color);
  font-weight: 200;
  font-size: 13px;
  outline: none;
  line-height: var(--menu-bar-height);
  padding: 0 15px;

  &:hover {
    color: yellow;
    background-color: var(--ui-element-color);
  }
}

.menu-bar li.active a {
  color: var(--ui-highlight-color);
}

.menu-bar li.active i {
  font-weight: 900;
}

.menu-bar .dropdown-menu li {
  border-top: 1px solid var(--ui-element-color);

  &:hover {
    background-color: var(--ui-active-color);
  }
}

.dropdown-menu {
  padding: 0;
  min-width: 200px;
  background-color: var(--ui-component-color);
  margin-top: 0;
  margin-left: -1px;
  border-bottom-left-radius: var(--ui-border-radius);
  border-bottom-right-radius: var(--ui-border-radius);
  border: var(--ui-border);
  border-top: 0;
  overflow: hidden;
}

.menu-bar .dropdown-menu a {
  padding: 3px 14px;
  line-height: 36px;
}

.dropdown-menu {
  /* Ensure fixed-width alignment of Black Tie icons */
  i {
    width: 17px;
    margin-right: 6px;
  }
}

.menu-bar li.open.dropdown {
  z-index: var(--z10-dropdown-button);

  > a {
    color: yellow;
    background-color: var(--ui-active-color);
  }
}

.menu-item-note {
  padding-left: 23px;
  font-size: 0.85em;
  color: #ababab;
  line-height: 1;
  margin-top: -0.8em;
  margin-bottom: 0.8em;
}

/* Small screens */
/* Bootstrap hamburger */
.navbar-toggle {
  position: relative;
  float: right;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px !important;
  padding: 0;
  width: 30px;
  height: 30px;
}

.navbar-toggle .icon-bar {
  display: block;
  width: 16px;
  height: 1px;
  margin: 0 auto;
  background-color: var(--ui-component-text-color);
}

.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 4px;
}

@media (min-width: 800px) {
  .navbar-toggle {
    display: none;
  }
}

.navbar-collapse {
  position: relative;
  background-color: var(--ui-base-color);
}

/* Sign in buttons */
.menu-sign-in {
  a[role='button'] {
    margin: 4px;
    border-radius: 3px;
    border: 1px solid #540101;
    padding: 0 0.75em; /* Override */
    background-color: #7a0000;
    color: #f1f1f1;
    box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.1);
    height: 32px; /* Override */
    line-height: 32px; /* Override */
  }

  /* hover style override */
  a[role='button']:hover {
    background-color: #8d0000;
    color: white;
  }

  /* disabled state */
  &.menu-sign-in-disabled a[role='button'] {
    background-color: #514e4e;
    border-color: #242323;
    color: #9e9d94;
    cursor: default;
  }

  /* open dropdowwn style override */
  &.open.dropdown > a {
    background-color: #8d0000 !important;
    color: white !important;
  }

  /* icon style override */
  .btm {
    padding-right: 0;
    padding-left: 0.25em;
  }

  .caret {
    display: none;
  }
}

.avatar {
  display: inline-block;
  margin-right: 0.5em;
  margin-top: -3px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  vertical-align: middle;
}

.avatar-blank {
  border: 1px solid white;
  width: 18px;
  height: 18px;
}

.avatar-admin-star {
  position: relative;
  margin-left: 0.15em;
  color: lightgoldenrodyellow;
  text-shadow: 0 0 1px #e69f00;
  font-size: 0.7em;
  top: -0.5em;
}
