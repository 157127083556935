/* Inputs */

.map-toolbar {
  ::-webkit-input-placeholder {
    color: #909195;
  }

  :-moz-placeholder {
    color: #909195;
  }

  ::-moz-placeholder {
    color: #909195;
  }

  :-ms-input-placeholder {
    color: #909195;
  }

  /* Much dislike Black Tie's pick of font smoothing */
  .btm {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }

  button {
    appearance: none;
    box-sizing: border-box;
    border: 0;
    padding: 0;
    height: 38px;
    width: 38px;
    font-size: 12px;
    background-color: var(--ui-element-color);
    color: var(--ui-component-text-color);
    cursor: pointer;
    outline: none;
  }

  button.active {
    background-color: var(--ui-active-color);
    color: var(--ui-highlight-color);
  }

  button:hover {
    background-color: var(--ui-active-color);
    color: var(--ui-highlight-color);
  }
}

.input-bar {
  display: flex;
  flex-direction: row;
}

input[type=text],
input[type=url] {
  background-color: var(--ui-well-color);
  appearance: none;
  border: var(--ui-border);
  border-radius: var(--ui-border-radius);
  box-shadow: var(--ui-well-shadow);
  color: var(--ui-component-text-color);
  font-size: 12px;
  font-family: var(--font-family);
  font-weight: 200;
  line-height: 24px;
  padding: 4px 8px;
  width: 100%;
  box-sizing: border-box;
}

input {
  outline: none;
}

input:focus {
  border: 1px solid var(--ui-highlight-color);
}

input:invalid {
  border: 1px solid red;
}

input::selection {
  background-color: var(--ui-component-color);
}

label + input {
  margin-top: 6px;
}

label {
  font-size: 13px;
  font-weight: 400;
  color: var(--ui-subtext-color);
}
