.embedded .workspace-container {
  top: 0;
  height: 100%;
}

.embedded .refresh-button {
  position: absolute;
  z-index: var(--z09);
  top: 4px;
  right: 4px;
  width: 30px;
  height: 30px;
}
