/* React DocsPanel */

.docs-panel {
  position: absolute;
  z-index: var(--z04-map-toolbar);
  bottom: 0;
  right: 0;
  width: 100%;
}

/* Style for map panel button to show it */
.docs-panel .docs-panel-button-show {
  position: absolute;
  right: 0;
  bottom: 0;
}

/* Docs divider */

.docs-divider {
  height: 8px;
  cursor: row-resize;
  display: flex;
  text-align: center;
  justify-content: center;
  user-select: none;
  background-color: var(--ui-component-color);
  border-bottom: var(--ui-border);
  border-top: 1px solid var(--ui-editor-background-color); /* slightly lighter for 3dness */
  box-shadow: var(--ui-emboss-shadow);
}

.docs-divider:hover {
  background-color: var(--ui-active-color);
}

.docs-divider-affordance,
.docs-divider-affordance::after,
.docs-divider-affordance::before {
  display: flex;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--ui-component-text-color);
  margin: auto;
}

.docs-divider-affordance::after,
.docs-divider-affordance::before {
  content: '';
  position: absolute;
}

.docs-divider-affordance::after {
  transform: translateX(10px);
}

.docs-divider-affordance::before {
  transform: translateX(-10px);
}

/* Style for map panel */
.docs-panel .panel {
  border: none;
  margin: 0;
  z-index: var(--z07-menu);
}

.docs-panel-collapsible {
  color: var(--ui-component-text-color);
  /* background-color: var(--ui-component-color); */
  background-color: #2e3033;
  transform: translate(0, 0) !important; /* This is to prevent React Draggable from changing panel position */

  /* Ease in and out of doc panel */
  /* transition-timing-function: ease-in-out;
    transition: 0.1s; */

  .panel-body {
    padding: 0;
    height: 100%;
  }

  .docs-panel-toolbar {
    display: flex;
    /* padding-top: 6px; */
    padding-bottom: 6px;
    flex-direction: row;
    height: 100%;
  }
}

.docs-panel button {
  margin: 4px;
  height: 30px;
  width: 30px;
}

.docs-panel-toolbar-content {
  width: 100%;
}

.docs-panel-toolbar-content .container {
  position: relative;
  height: 100%;
  padding: 20px 16px 20px 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Toolbar buttons */

.docs-panel-toolbar-toggle {
  position: absolute;
  right: 0;
}

/* Content formatting */

.capitalize {
  text-transform: capitalize;
}

.child-row {
  padding-left: 15px;
  padding-bottom: 10px;
}

.toolbar-content-row {
  padding-bottom: 10px;
  font-size: 14px;
}

code {
  background-color: transparent;
}

.docs-link code {
  color: #bf4d6a;
  cursor: pointer;
  text-decoration: underline;
}

.docs-link code:hover {
  text-shadow: 0 0 1px #c34646;
}
