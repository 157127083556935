/* SHIELD */

.shield {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  user-select: none;
  pointer-events: auto;
}
