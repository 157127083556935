/* MODALS · WHAT'S NEW? */

.welcome-modal {
  width: 600px;
}

.modal-welcome-text p {
  text-align: left;
  padding: 1em 3.25em;
}

.welcome-modal .call-to-action {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: -1em;
  overflow: hidden;
  text-align: left;
  display: flex;

  button {
    display: inline-block;
    width: 50%;
    height: 80px;
    margin-top: 0 !important;
  }

  button:first-of-type {
    margin-right: 1em;
  }
}

.whatsnew-modal {
  width: 600px;
  /* Allow the height of this modal to expand on larger screens */
  height: calc(100% - 400px);
  min-height: 600px;
  max-height: 1200px;
}

.whatsnew-modal-changelog {
  /* Ensure this fits within a variable-height modal.
     TODO: Don't use a magic number */
  height: calc(100% - 94px);
  box-sizing: border-box;
}

.changelog-frame {
  border: 0;
  width: 100%;
  height: 100%;
}

.changelog {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1em;
  background-color: var(--ui-component-color);
  color: var(--ui-component-text-color);
  font-weight: 300;
  line-height: 1.4;

  a {
    color: lightblue;
    text-decoration: underline;
  }

  h2 {
    margin-top: 0;
    font-size: 1.65em;
    padding-bottom: 0.25em;
    border-bottom: 2px solid var(--ui-element-color);
  }

  h3 {
    font-size: 1.25em;
  }

  article:not(:first-of-type) {
    border-top: 3px solid var(--ui-active-color);
    padding-top: 1em;
  }

  img {
    width: 100%;
  }

  .changelog-image {
    background-size: cover;
    background-position: center center;
    height: 200px;
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  ul {
    padding-left: 2em;
  }

  li {
    margin-top: 1em;
  }
}
