/* Overrides the indentation alignment (a hack for cm's on `renderLine` event) */
.CodeMirror-linewidget {
  left: 0 !important;
  padding-left: 0 !important;
}

.error,
.warning {
  position: relative;
  font-family: var(--editor-font-family);
  padding-left: 3em; /* Theoretically, aligns error text to the first tab stop */
  padding-right: 10px;
}

.error-icon,
.warning-icon {
  position: absolute;
  left: 0;
  top: 3px;
  width: 3em; /* Centers the icon within the first tab stop */
  text-align: center;
}

.error {
  background: var(--ui-error-color);
  color: white;
}

.warning {
  background: var(--ui-warning-color);
  color: black;
}

/* Experiment. */
.errors-panel {
  padding: 0; /* Overrides .modal */
  text-align: left;
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: 400px;
  overflow: hidden;
  pointer-events: auto;

  .btm {
    position: absolute;
    width: 18px;
    top: 1px;
    text-align: center;
    font-weight: 600;
  }

  .bt-exclamation-triangle {
    color: var(--ui-error-color);
  }

  .bt-exclamation-circle {
    color: var(--ui-warning-color);
  }
}

.errors-panel-viewport {
  position: relative;
  width: 100%;
  max-height: 400px; /* Prevent it from getting out of hand if something generated tons of errors */
  overflow: auto;
}

.errors-panel-content {
  padding: 10px;
  user-select: auto;

  a {
    white-space: nowrap;
    color: lightblue;
    text-decoration: underline;
  }
}

.errors-panel-item {
  position: relative;

  &:not(:first-of-type) {
    margin-top: 0.85em;
  }
}

.errors-panel-text {
  margin-left: 22px;
}
