#a-sandbox {
  margin-top: -33px;
  position: relative;
  width: 134px;
  height: 134px;
  padding: 0;
  background: #fc0;
  box-shadow: 5px 5px 10px #111;
  z-index: var(--z01-sandbox);
}

#a-sandbox::after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 9px 0 9px 10px;
  border-color: transparent #fc0;
  display: block;
  width: 0;
  z-index: var(--z01-sandbox);
  right: -10px;
  top: 14px;
}

#a-sandbox-canvas {
  margin: 2px;
  z-index: var(--z01-sandbox);
  overflow: hidden;
  mask-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC');
}

#a-sandbox-colorpicker {
  position: relative;
  top: -132px;
  left: 112px;
  border: 1px solid #aaa;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 2px white;
  cursor: pointer;
  background-color: white;
}
