/* React: Color Palette */

.colorpalette {
  position: absolute;
  z-index: var(--z01-colorpalette);
  right: 18px;
  bottom: 12px;
  display: flex;
  flex-direction: row;
}

.colorpalette-color {
  display: inline-block;
  position: relative;
  padding-left: 2px;
  padding-right: 2px;
}

.colorpalette-square {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
