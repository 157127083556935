/* APPLICATION */

* {
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: border-box;
}

/*
  Elements are fixed in position to prevent layout bugs from accidentally
  scrolling content to weird positions. There is also a theory that this
  improves performance (since it never needs to move and position is never
  recalculated)
*/
html,
body,
.application-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

html {
  font-family: var(--font-family);
  font-size: var(--root-font-size);

  /* fix Safari font weight being too light sometimes */
  -webkit-font-smoothing: subpixel-antialiased;
}

body {
  margin: 0;
  border: 0;
  padding: 0;
  background-color: var(--ui-base-color);
}

/* SCROLLBARS - WEBKIT ONLY */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background-color: var(--ui-element-color);
}

::-webkit-scrollbar-thumb {
  background-color: #b9b9bd;
}

::-webkit-scrollbar-corner {
  background-color: var(--ui-base-color);
}

/* MOBILE MESSAGE */

.mobile-message,
.mobile-message * {
  box-sizing: border-box;
}

.mobile-message {
  display: none;
  position: relative;
  padding: 10px;
  padding-right: 48px;
  font-size: 16px;
  font-family: var(--font-family);
  font-weight: 400;
  background-color: lightyellow;
  z-index: var(--z10-mobile-message);
}

.mobile-message strong {
  font-weight: normal;
  color: darkred;
}

.mobile-message-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  padding-top: 8px;
  font-size: 20px;
  text-align: center;
  color: darkred;
  cursor: pointer;
}

/* APP LOADER */
/* via http://projects.lukehaas.me/css-loaders/ */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden; /* Turned on via js */
}

.loader {
  font-size: 4px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: linear-gradient(to right, var(--ui-component-text-color) 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  animation: load3 1.4s infinite linear;
  transform: translateZ(0);
}

.loader::before {
  width: 50%;
  height: 50%;
  background: var(--ui-component-text-color);
  border-radius: 100% 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.loader::after {
  background: var(--ui-base-color);
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
