.call-to-action {
  text-align: center;
  padding: 3em;
  overflow-y: auto;
  background-color: #26282e;
  border-radius: 3px;
  font-size: 1rem;
  min-width: 300px;

  button:first-of-type {
    margin-top: 2em;
  }

  button {
    display: block;
    margin: 0;
    margin-top: 1em;
    width: 100%;
    padding: 1.5em;
    font-size: 1.1em;
  }
}
