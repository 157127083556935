.editor-hidden-tooltip {
  position: fixed;
  min-width: 175px;
  top: 120px;
  right: 18px;
  padding: 0.8em;
  padding-right: 1em;
  border-radius: var(--ui-border-radius);
  background-color: var(--ui-tooltip-background-color);
  font-size: 1rem;
  color: var(--ui-component-text-color);
  z-index: var(--z09-tooltip);
  pointer-events: none;
  user-select: none;

  /* Pointer tip */
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 12px solid var(--ui-tooltip-background-color);
    top: 50%;
    margin-top: -12px;
    left: 100%;
  }
}

.editor-hidden-tooltip h5 {
  font-weight: bold;
  font-size: 1em;
}

.editor-hidden-tooltip p {
  font-weight: 200;
  margin-bottom: 0;
}
