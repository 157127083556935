
.code-snippet-modal {
  width: 660px;
}

.code-snippet-textarea-container {
  width: 600px;
  height: 500px;
  margin-bottom: 1em;

  textarea {
    width: 100%;
    height: 100%;
    resize: none;
    font-family: var(--editor-font-family);
    font-size: 1em;
    padding: 1em;
    white-space: nowrap;
  }
}

/* TODO: Generalize tab stuff. these are for non-editor tabs */
.code-snippet-tabs {
  display: inline-block;
}

.code-snippet-tab {
  display: inline-block;
  padding: 0.65em 1em;
  border: 1px solid var(--ui-border-color);
  border-bottom: 0;
  border-top-left-radius: var(--ui-border-radius);
  border-top-right-radius: var(--ui-border-radius);
  margin-right: 3px;
  margin-bottom: -1px;
  cursor: pointer;
  background-color: var(--ui-base-color);
  box-shadow: inset 0 1px 2px 0 var(--ui-component-color);
}

.code-snippet-tab:hover {
  color: var(--ui-highlight-color);
}

.code-snippet-tab-selected {
  color: var(--ui-highlight-color);
  background-color: var(--ui-element-color);
  box-shadow: var(--ui-emboss-shadow);
}

.code-snippet-tabs-right {
  display: inline-block;
  float: right;
  line-height: 30px;
}
