/* CodeMirror text markers */

.CodeMirror-widget {
  /* Resets this property for wrapped lines. The hanging indent adds
     its own text-indent and we do not want widgets to inherit it. */
  text-indent: initial;
}

/* CodeMirror text markers and popups */
/* Root element inserted by CodeMirror */
.textmarker-root {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  background-color: none;
  user-select: none;
}

/* Generic top-level namespace for textmarker elements, which will be a direct
   child element inserted into the root element. */
.textmarker {
  display: inline-block;
  position: relative;
  margin: 0 0.5em;
  user-select: none;
}

/* TODO: Rename, merge with floating-panel ? */
.widget-modal {
  display: inline-flex !important;
  width: auto;
  padding: 0;
  outline: none;
  box-shadow: var(--ui-modal-shadow);
  background-color: var(--ui-component-color);
  z-index: var(--z09-pickers);
}

/* INDIVIDUAL TEXT MARKERS AND PICKERS */
/* *********************************** */

/* GLSL pickers */

.glsl-picker-canvas {
  background-color: var(--ui-element-color);
}

/* Boolean (true/false toggle) */

.textmarker-boolean {
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: var(--ui-border-radius);
  width: 1em;
  height: 1em;
  cursor: pointer;
  margin-top: -0.15em;
  display: flex;
  overflow: hidden;
}

.textmarker-boolean label {
  display: flex;
}

.textmarker-boolean input[type=checkbox] {
  position: relative;
  outline: none;
  margin-top: -0.15em;
  cursor: pointer;
}

.textmarker-boolean input[type=checkbox]::after {
  content: '\2717';
  color: white;
  visibility: visible;
  display: flex;
  text-align: center;
  justify-content: center;
  background-color: var(--ui-element-color);
}

.textmarker-boolean input[type=checkbox]:checked::after {
  content: '\2713';
  color: white;
}

/* Dropdown selector */

.textmarker-dropdown {
  display: inline-flex;
  position: relative;
  font-weight: 400;
  margin-left: 10px;
  margin-right: 10px;
  outline: none;
  border-radius: 3px;
}

.textmarker-dropdown select {
  font-family: var(--font-family);
  font-size: 100%;
  font-weight: 400;
  outline: none;
  background-color: #5e6064;
  color: white;
  border: 0;
  height: 1.2em;
  width: 1em; /* Remove width to get full dropdown to appear */
  border-radius: 4px;
  padding-left: 16px;
}

.textmarker-dropdown option {
  outline: none;
  margin-left: 20px;
}

select {
  appearance: none;
  outline: none;
  border: 0;
  width: 100%;
  background: #fff url('../data/imgs/arrow_down.png') no-repeat;
  background-size: 16px;
  background-position: left center;
}

/* Vector picker */

/* Should probably simplify this for color picker too.
   just refers to button being clicked to open modal */
.textmarker-vectorpicker {
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.15);
  max-width: 14px;
  max-height: 14px;
  width: 1em;
  height: 1em;
  cursor: pointer;
  vertical-align: middle;
  margin-top: -0.15em;
}
