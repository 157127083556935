/* OVERLAYS */

/* Container for all overlays */
.overlay-container,
.modals-container,
.modal-container {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z08-overlay);
}

.overlay-container {
  pointer-events: none;
}
