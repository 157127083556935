/* MODALS · OPEN SCENES */

.open-scene-modal {
  width: 600px;
  /* Allow the height of this modal to expand on larger screens */
  height: calc(100% - 400px);
  min-height: 600px;
  max-height: 1200px;
}

.open-scene-list {
  /* Ensure this fits within a variable-height modal.
     TODO: Don't use a magic number */
  height: calc(100% - 94px);
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--ui-component-color);
}

.open-scene-option {
  display: flex;
  flex-direction: row;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: var(--ui-active-color);
  }
}

.open-scene-deleting {
  background-color: transparent;
}

/* We need a container element for the image so we can highlight, see below */
.open-scene-option-thumbnail {
  position: relative;
  margin-right: 10px;
  line-height: 0; /* Removes baseline from image */

  img {
    display: block; /* Force display of img tag without a `src` attribute in Firefox */
    width: 144px;
    height: 81px;
  }
}

/* Use .open-scene-selected for focus state */
.open-scene-selected {
  background-color: var(--ui-active-color);

  &:focus {
    outline: none;
  }
}

.open-scene-selected:not(.open-scene-deleting) {
  .open-scene-option-name {
    color: var(--ui-highlight-color);
  }
}

/* box-shadow is overlapped by the image, so a pseudo element puts it in the right place */
.open-scene-selected:not(.open-scene-deleting) .open-scene-option-thumbnail::after {
  position: absolute;
  display: block;
  content: '';
  top: 0;
  left: 0;
  /* Ensure same dimensions as thumbnail image */
  width: 144px;
  height: 81px;
  box-shadow: inset 0 0 0 1px var(--ui-highlight-color);
}

.open-scene-option-info {
  flex-grow: 1;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
}

.open-scene-option-name {
  line-height: 24px;
  font-size: 1.1em;
}

.open-scene-option-description {
  line-height: 1.4;
  flex-grow: 1;
  color: var(--ui-subtext-color);
}

.open-scene-option-date {
  font-size: 0.8em;
  color: var(--ui-subtext-color);
  /* Breathing room in case description gets too close to where the date output is. */
  margin-top: 0.5em;
}

.open-scene-deleting {
  cursor: default;

  .open-scene-option-name,
  .open-scene-option-description,
  .open-scene-option-date {
    color: gray;
  }

  .open-scene-option-thumbnail {
    opacity: 0.35;
  }
}

.open-scene-option-tasks {
  visibility: hidden;
}

.open-scene-option:hover .open-scene-option-tasks {
  visibility: visible;
}
